import React, { useState } from 'react';
import Logo from '../images/Thrive Library Logo.png';
import { Navigate, Link } from 'react-router-dom';

/**For Testing out login functionality */
const database = [
  {
    id: "SD00000000",
    password: "pass1"
  },
  {
    id: "SD00000230",
    password: "pass2"
  }
];

export {database}

export default function SignIn(setUser){
    const [errorMessages, setErrorMessages] = useState({});
    const [isSubmitted, setIsSubmitted] = useState(false);
    const errors = {
        id: "Invalid ID",
        pass: "Invalid Password"
      };

    const handleSubmit = (event) => {
        event.preventDefault();
    
        var { id, pass } = document.forms[0];
        const userData = database.find((user) => user.id === id.value);
        // Store user's info locally to keep user signed in
        localStorage.setItem("user", JSON.stringify(userData));

        if (userData) {
            if (userData.password !== pass.value) {
              setErrorMessages({ name: "pass", message: errors.pass });
            } else {
              setIsSubmitted(true);
            }
          } else {
            setErrorMessages({ name: "id", message: errors.id });
          }
        };

        const renderErrorMessage = (name) =>
            name === errorMessages.name && (
                <div className="error-msg">{errorMessages.message}</div>
            );

        const renderForm = (
            <section>
            <div className='container-1'>
                <div className='container-2'>
                    <img className='thrive-logo' src={Logo} alt='Cognitio Logo'></img>
                    <div className='container-3'>
                        <span className='intro'>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. 
                            Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet
                        </span>
                        <span className='intro-2'>Fullname </span>
                        <span className='intro-3'>Occupation and work place</span>
                    </div>
                </div>
            </div>
            <h2 className='login-top'>Login to ThriveLibrary Account</h2>
            <h4 className='id-input'>User ID</h4>
            <h4 className='password-input'>Password</h4>
            <div className='container-row'>
                <form className='login-form' onSubmit={handleSubmit}>
                    <input type='text' placeholder='SD00000000' name="id" className='id' required></input>
                    {renderErrorMessage("id")}
                    <input type='password' placeholder='********' name="pass" className='passinput' required></input>
                    {renderErrorMessage("pass")}
                    <button type='submit' className='signin'>Login</button>
                </form>
            </div>
            <Link to="/signup" className='signin-redirect'>Don't have an account?</Link>
        </section>
        );
    
    

    return(
        <div>
        {isSubmitted ? 
        <Navigate to="/homepage" />
        : renderForm}
      </div>
    )

}