import React from "react";

function Rating({ rating }) {
  const fullStar = "★";
  const emptyStar = "☆";
  const maxRating = 5;

  const filledStars = Math.round(rating);
  const unfilledStars = maxRating - filledStars;

  const stars = Array(filledStars).fill(fullStar).concat(Array(unfilledStars).fill(emptyStar));
  const starString = stars.join("");

  return <span className="rating">{starString}</span>;
}

export default Rating;
