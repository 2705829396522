import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { HiOutlineLibrary } from 'react-icons/hi';
import { MdPeople, MdHistory } from 'react-icons/md';
import { TiMessages } from 'react-icons/ti';
import { BiMessageAltDetail, BiSearch, BiLogOut } from 'react-icons/bi';
import { RxAvatar } from 'react-icons/rx';
import { RiArrowDropDownLine } from 'react-icons/ri'; 
import { BsPerson } from 'react-icons/bs';
import { GoArchive } from 'react-icons/go';
import Logo from "../../images/Thrive Library Logo.png";

export default function Navbar({ onSearch }) {
    const handleInputChange = (event) => {
      const searchTerm = event.target.value;
      onSearch(searchTerm);
    };
    const [active, setActive] = useState('');

    const handleItemClick = (item) => {
        setActive(active === item ? '' : item);
    };

    useEffect(() => {
        const path = window.location.pathname;
        if (path === '/homepage') {
            setActive('library');
        } else if (path === '/clubpage') {
            setActive('club');
        } else if (path === '/chatpage') {
            setActive('message');
        } else if (path === '/requestpage') {
            setActive('request');
        }
    }, []);

    const [showMenu, setShowMenu] = useState(false);

    const handleDropdown = () => {
        setShowMenu(!showMenu);
    };

    return (
        <div className='nav'>
            <img src={Logo} className='logo-home' alt='ThriveLibrary' />
            <form onSubmit={(event) => event.preventDefault()}>
                <input 
                    className='search-box-input' 
                    type="text"
                    placeholder="Search"
                    onChange={handleInputChange}
                />
                <button className='search-icon' type="submit"><BiSearch /></button>
            </form>
            <div>
                <div onClick={() => handleItemClick('library')}>
                    <HiOutlineLibrary className={`school-nav ${active === 'library' ? 'active' : ''}`} />
                    <Link to='/homepage' className={`library ${active === 'library' ? 'active' : ''}`}>
                        <span className=''>Library</span>
                    </Link>
                </div>
                <div onClick={() => handleItemClick('club')}>
                    <MdPeople className={`club-nav ${active === 'club' ? 'active' : ''}`} />
                    <Link to='/clubpage' className={`club ${active === 'club' ? 'active' : ''}`}>
                        <span className=''>Club</span>
                    </Link>
                </div>
                <div onClick={() => handleItemClick('message')}>
                    <TiMessages className={`message-nav ${active === 'message' ? 'active' : ''}`} />
                    <Link to='/chatpage' className={`message ${active === 'message' ? 'active' : ''}`}>
                        <span className=''>Message</span>
                    </Link>
                </div>
                <div onClick={() => handleItemClick('request')}>
                    <BiMessageAltDetail className={`request-nav ${active === 'request' ? 'active' : ''}`}/>
                    <Link to='/requestpage' className={`request ${active === 'request' ? 'active' : ''}`}>
                        <span className=''>Request</span>
                    </Link>
                </div>
            </div>
            <div className='username'>Hi, Comfort<br />ST00001</div>
            <RxAvatar className='avatar'/>
            <span className='dropdown-menu' onClick={handleDropdown}>
                <RiArrowDropDownLine className='dropdown' />
                {showMenu && (
                    <div className='dropdown-menu-items'>
                        <Link to='/profilepage'>
                            <div className='menu-item'>
                                <BsPerson />
                                <span>Profile</span>
                            </div>
                        </Link>
                        <Link to='/archivepage'>
                            <div className='menu-item'>
                                <GoArchive />
                                <span>Archive</span>
                            </div>
                        </Link>
                        <Link to='/historypage'>
                            <div className='menu-item'>
                                <MdHistory />
                                <span>History</span>
                            </div>
                        </Link>
                        <Link to='/'>
                            <div className='menu-item'>
                                <BiLogOut />
                                <span>Logout</span>
                            </div>
                        </Link>
                    </div>
                )}
            </span>
        </div>
    );
}