import React, { useState } from "react";
import Navbar from "../ui/Navbar";
import { MdOutlinePeople } from "react-icons/md";
import { BsPerson, BsChatSquare, BsHandThumbsUp } from 'react-icons/bs';
import founderImg from '../../images/avatar.png';

const Clubs = [
    {
        id: 1,
        createrPosition: "Teacher",
        founderImage: founderImg,
        teacherName: "Abel Thomson",
        clubName: "How to Build your vocabulary in four weeks",
        dateCreated: "17/03/2021",
        author: "By Dr Ben Bruse",
        members: "123",
        comments: "214",
        likes: "105",
    },
    {
        id: 2,
        createrPosition: "Teacher",
        founderImage: founderImg,
        teacherName: "Abel Thomson",
        clubName: "How to Build your vocabulary in four weeks",
        dateCreated: "17/03/2021",
        author: "By Dr Ben Bruse",
        members: "123",
        comments: "214",
        likes: "105", 
    },
    {
        id: 3,
        createrPosition: "Teacher",
        teacherName: "Abel Thomson",
        founderImage: founderImg,
        clubName: "How to Build your vocabulary in four weeks",
        dateCreated: "17/03/2021",
        author: "By Dr Ben Bruse",
        members: "123",
        comments: "214",
        likes: "105",
    },
    {
        id: 4,
        createrPosition: "Teacher",
        teacherName: "Abel Thomson",
        founderImage: founderImg,
        clubName: "How to Build your vocabulary in four weeks",
        dateCreated: "17/03/2021",
        author: "By Dr Ben Bruse",
        members: "123",
        comments: "214",
        likes: "105",
    }
]

export default function Club(){
    const [showAllClubs, setShowAllClubs] = useState(false);
    const clubsToDisplay = showAllClubs ? Clubs : Clubs.slice(0, 3);

    const toggleShowAllClubs = () => {
        setShowAllClubs(!showAllClubs);
    };
    return(
        <div>
            <Navbar />
            <span className="header">Club</span>
            <div className="create-club">
                <span><MdOutlinePeople /></span>
                <button>Create Reading Club</button>
            </div>
            <div className="club-grid-container">
                <div className="club-grid">
                    {clubsToDisplay.map((club) => {
                        return (
                        <div key={club.id} className="club-container">
                            <img src={club.founderImage} alt='founder' className="founder-image"></img>
                            <p className="club-founder">{club.teacherName}</p>
                            <p className="founder-position">{club.createrPosition}</p>
                            <p className="club-date">Created <br />{club.dateCreated}</p>
                            <p className="club-name">{club.clubName}</p>
                            <p className="clubBook-author">{club.author}</p>
                            <p className="tot-members">{club.members}</p>
                            <span className="members-icon"><BsPerson /></span>
                            <p className="tot-comments">{club.comments}</p>
                            <span className="comments-icon"><BsChatSquare /></span>
                            <p className="tot-likes">{club.likes}</p>
                            <span className="likes-icon"><BsHandThumbsUp  /></span>
                        </div>
                        );
                    })}
                    {Clubs.length > 3 && !showAllClubs && (
                        <button className='show-club' onClick={toggleShowAllClubs}>View More</button>
                    )}
                    {Clubs.length > 3 && showAllClubs && (
                        <button className='show-less' onClick={toggleShowAllClubs}>View Less</button>
                    )}
                </div>
            </div>
        </div>
    );
}