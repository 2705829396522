import React from 'react';
import { useState } from 'react';
import Logo from '../images/Thrive Library Logo.png'; 
import {useForm} from 'react-hook-form';
import { Navigate, Link } from 'react-router-dom';

export default function SignUp(){
    const [isSubmitted, setIsSubmitted] = useState(false);;
    const { register, handleSubmit, formState: { errors } } = useForm();
    /** Testing out user input data collection*/
    const onSubmit = () => setIsSubmitted(true); 
    const renderForm = (<section>
        <div className='container-1'>
            <div className='container-2'>
                <img className='thrive-logo' src={Logo} alt='Cognitio Logo'></img>
                <div className='container-3'>
                    <span className='intro'>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. 
                        Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet
                    </span>
                    <span className='intro-2'>Fullname </span>
                    <span className='intro-3'>Occupation and work place</span>
                </div>
            </div>
        </div>
        <h2 className='top'>Sign Up to Cognitio Account</h2>
        <h4 className='name-input'>Full Name</h4>
        <h4 className='pass-input'>Password</h4>
        <h4 className='class-input'>Class/Department</h4>
        <h4 className='grade-input'>Grade/Level</h4>
        <form onSubmit={handleSubmit(onSubmit)}>
            <input className='grades' type='text' {...register("class")} placeholder='500 Level' required></input>
            <input className='name' type='text' {...register("fullname")}placeholder='Ibrahim Chris' required></input>
            <input className='pass' type='password' {...register("passcode",{ required : true, minLength: 8})}placeholder='********'></input>
            <span className='errors'>{errors.passcode?.type === "required" && "Password is required"}</span>
            <span className='errors'>{errors.passcode?.type === "minLength" && "Password too short"}</span>
            <input className='class' type='text' {...register("course")}placeholder='Art and Design' required></input>
            <button className='btn' type='submit'>Sign Up</button>
        </form>
        <Link to="/" className='signup-redirect'>I already have an account</Link>
    </section>
    );

    return(
        <div>
            {isSubmitted ? 
            <Navigate to="/" />
            : renderForm}
        </div>      
    )
}