import React, { useState } from 'react';
import BookUpload from '../ui/BookUpload';
import VideoUpload from '../ui/VideoUpload';

function Upload({ handleBookSubmit, handleVideoSubmit }) {
  const [activeTab, setActiveTab] = useState('book');

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
  <div>
    <div className='book-button'>
      <button onClick={() => handleTabClick('book')} className={activeTab === 'book' ? 'active' : 'b-title'}>Upload Book</button>
    </div>
    <div className='video-button'>
      <button onClick={() => handleTabClick('video')} className={activeTab === 'video' ? 'active' : 'v-title'}>Upload Video</button>
    </div>
    {activeTab === 'book' && <BookUpload handleBookSubmit={handleBookSubmit} />}
    {activeTab === 'video' && <VideoUpload handleVideoSubmit={handleVideoSubmit} />}
  </div>

  );
}

export default Upload;
