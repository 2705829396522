import React, { useState } from 'react';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import { MdCancel } from 'react-icons/md';

function BookUpload({ handleBookSubmit }) {
  const [bookTitle, setBookTitle] = useState('');
  const [bookAuthor, setBookAuthor] = useState('');
  const [otherContributors, setOtherContributor] = useState('');
  const [bookEdition, setBookEdition] = useState('');
  const [book, setBook] = useState(null);
  const [image, setImage] = useState(null);

  const handleSubmit = (event) => {
    event.preventDefault();
    handleBookSubmit({ 
        title: bookTitle, 
        author: bookAuthor,
        other: otherContributors,
        edition: bookEdition,
        book: book,
        image: image
     });
    setBookTitle('');
    setBookAuthor('');
    setBookEdition('');
    setOtherContributor('');
    setBook(null);
    setImage(null);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];
    if (droppedFile.type === 'application/pdf') {
      setBook(droppedFile);
    } else {
      alert('Please drop a PDF file.');
    }
  };
  
  const handleCoverClick = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/jpeg, image/png";
    input.onchange = (e) => {
        const file = e.target.files[0];
        setImage(file);
    };
    input.click();
  };

  const handleCoverDrop = (event) => {
    event.preventDefault();
    const droppedCover = event.dataTransfer.files[0];
    if (droppedCover.type === 'image/jpeg' || droppedCover.type === 'image/png') {
      setImage(droppedCover);
    } else {
      alert('Please drop a JPEG or PNG file.');
    }
  };

  const handleRemoveImage = () => {
    setImage(null);
  };

  const handleRemoveBook = () => {
    setBook(null);
  };

  const handleBookClick = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "application/pdf";
    input.onchange = (e) => {
        const file = e.target.files[0];
        setBook(file);
    };
    input.click();
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleCoverDragOver = (event) => {
    event.preventDefault();
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <label className='book-title'>
          Publication Title
          <input className='book-input' placeholder='History of Man' type="text" value={bookTitle} onChange={(event) => setBookTitle(event.target.value)} />
        </label>

        <label className='books-author'>
          Author(s)
          <input className='author-input' placeholder='Sophie Kalu' type="text" value={bookAuthor} onChange={(event) => setBookAuthor(event.target.value)} />
        </label>

        <label className='book-other'>
          Other Contributors
          <input type="text" className='other-input' placeholder='Sophie Kalu' value={otherContributors} onChange={(event) => setOtherContributor(event.target.value)} />
        </label>

        <label className='book-edition'>
          Edition
          <input type="text" className='edition-input' placeholder='2nd' value={bookEdition} onChange={(event) => setBookEdition(event.target.value)} />
        </label>

        <label className='cover-title'>Attach Book Cover</label>
        <div 
            className='cover-upload' 
            onDrop={handleCoverDrop} 
            onDragOver={handleCoverDragOver} 
            onClick={handleCoverClick}
            >
            {!image ? (
                <div>Drag and Drop or Click to Browse</div>
            ) : (
                <div>
                {image.name}
                <span onClick={handleRemoveImage}>
                    <MdCancel />
                </span>
                </div>
            )}
        </div>
        <label className='png-upload'>Attached file type: JPEG/PNG</label>
        
        <label className='bookhome-title'>Attach Book File</label>
        <div 
            className='book-upload' 
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onClick={handleBookClick}
            >
            {!book ? (
                <div>Drag and Drop or Click to Browse</div>
            ) : (
                <div>
                {book.name}
                <span onClick={handleRemoveBook}>
                    <MdCancel />
                </span>
                </div>
            )}
        </div>
        <label className='pdf-upload'>Attached file type: PDF</label>

        <button type="submit" className='button-upload'>
            <span><AiOutlineCloudUpload /></span>
            Upload
        </button>
      </form>
    </div>
  );
}

export default BookUpload;